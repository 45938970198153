<template>
  <v-container>
    <vue-snotify></vue-snotify>
    <v-card width="100%" height="100%" elevation="0" class="mb-10 ml-10">
      <v-row>
        <v-col cols="12" md="12"> </v-col>
        <v-row>
          <v-col cols="12" md="1"></v-col>
          <v-col style="background-color: #0c71af" cols="12" md="4">
            <br /><br /><br /><br /><br />
            <p style="black" class="
                text-center
                mt-10
                text-h4
                arial
                font-weight-bold
                white--text
              ">
              ¿TIENES ALGUNAS CONSULTAS?
            </p>
            <div>
              <p class="text-h7 pl-15 ml-10 text-left white--text"></p>
              <div class="text-center">
                <v-icon class="text-h2 white--text text-center">mdi-map-marker</v-icon>
              </div>
              <p class="text-h5 text-center white--text">
                BENAVENTE 575 OF. 202
              </p>
              <p class="text-h6 text-center white--text mb-0">Horario de atención</p>
              <p class="text-h7 text-center white--text mb-0">Lunes - Viernes</p>
              <p class="text-h7 text-center white--text mb-5 pb-5">09:00 a 15:30 hrs</p>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <p style="color: #cfd8dc" class="text-left text-h5 arial font-weight-bold secondary--text">
              ENVÍA UN MENSAJE
            </p>
            <template>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field v-model="name" :counter="10" :rules="nameRules" label="Nombre" required></v-text-field>

                <v-text-field v-model="email" :rules="emailRules" label="Correo" required></v-text-field>

                <v-select v-model="select" :items="items" :rules="[(v) => !!v || 'Debe seleccionar un servicio']"
                  label="Servicios" required></v-select>

                <v-textarea v-model="description" :rules="descriptionRules" label="Descripción" required>
                </v-textarea>
                <div class="text-right">
                  <v-btn :disabled="!valid" color="primary" outlined class="mr-4" width="125" @click="sendEmail">
                    <v-icon>mdi-send-circle</v-icon> Enviar
                  </v-btn>

                  <v-btn color="primary" outlined width="125" class="mr-4" @click="reset">
                    <v-icon>mdi-close-circle</v-icon> Cancelar
                  </v-btn>
                </div>
              </v-form>
            </template>
          </v-col>
          <v-col cols="12" md="1"></v-col>
        </v-row>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,
  data: () => ({
    valid: true,
    name: "",
    nameRules: [
      (v) => !!v || "El nombre es requerido",
      (v) =>
        (v && v.length <= 10) || "El nombre debe tener menos de 10 caracteres",
    ],
    description: "",
    descriptionRules: [
      (v) => !!v || "Debe insertar una descripción",
      (v) =>
        (v && v.length <= 250) ||
        "El nombre debe tener menos de 250 caracteres",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "El correo es requerido",
      (v) => /.+@.+\..+/.test(v) || "Debe ser un correo válido",
    ],
    select: null,
    items: [
      "Servicio",
      "Boleta electrónica",
      "Factura electrónica",
      "Firma electrónica",
      "Hosting",
      "Equipos",
    ],
    checkbox: false,
  }),

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    sendEmail() {
      console.log("enviando email");
      let request = {
        'name': this.name,
        'service': this.select,
        'from': this.email,
        'body': this.description
      };
      console.log('name -> ' + this.name);
      console.log('service -> ' + this.select);
      console.log('from -> ' + this.email);
      console.log('body -> ' + this.description);
      axios
        .post("/send-email", request)
        .then((response) => {
          this.displayNotification("success", "Éxito", "Mensaje enviado. Pronto le contactaremos");
          console.log(response.data);
          setTimeout(() => this.$router.push({ name: "home" }), 4000);
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al enviar mensaje");
          setTimeout(() => this.$router.push({ name:"contact"}), 4000);
        });
    },
  },
};
</script>
